<template>
  <el-container style="height: calc(100vh - 65px)">
    <el-main class="layout-main">
      <!-- <el-button type="" @click="getData">获取数据</el-button> -->
      <el-form
        :model="formData"
        :inline="true"
        label-position="left"
        class="formData"
        v-if="queryOptions.length > 0"
        disabled
      >
        <el-form-item
          v-for="item in isShowQueryOptions.filter((item) => item.isShow)"
          :key="item.code"
          :label="item.title"
          label-width="100px"
          style="margin-left: 20px"
        >
          <component :is="item.name" :item="item" :formData="formData">
          </component>
        </el-form-item>
      </el-form>
      <el-table :data="data" border>
        <el-table-column
          v-for="(item, index) in listOptions.filter((item) => item.isShow)"
          :label="item.title"
          :prop="item.code"
          :key="index"
          :width="flexColumnWidth(item.title)"
          align="center"
        >
          <el-table-column
            v-for="(itemInfo, i) in item.items"
            :label="itemInfo.title"
            :prop="itemInfo.code"
            :key="i"
            :width="flexColumnWidth(itemInfo.title)"
            align="center"
          >
          </el-table-column>
        </el-table-column>
      </el-table>
    </el-main>
    <div
      v-if="isShowOption"
      style="
        position: fixed;
        width: 200px;
        height: fit-content;
        background-color: #fff;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        padding: 0 5px 10px;
        color: #222;
      "
      :style="{ left: location.left + 'px', top: location.top + 'px' }"
    >
      <div style="padding: 5px">
        默认值
        <i
          class="el-icon-close"
          style="float: right; padding-top: 2px"
          @click="isShowOption = false"
        ></i>
      </div>
      <el-select
        v-model="activeComp.defaultValue"
        placeholder="请选择"
        @change="changeDefaultVal"
      >
        <el-option
          v-for="item in defaultOptions"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>
    </div>
    <el-aside class="layout-param">
      <div class="fontSize">字段设置</div>
      <div style="padding-left: 15px">
        <div style="margin: 20px 0; padding-right: 20px">
          <span style="font-weight: 700">查询条件</span>
          <span style="float: right"
            >全选<el-checkbox
              v-model="queryCheckedAll"
              style="margin-left: 20px"
              @change="handlerQueryCheckedAll"
          /></span>
        </div>
        <draggable
          :list="queryOptions"
          group="queryOptions"
          handler=".el-icon-rank"
          :options="dragOption"
        >
          <div
            v-for="(op, index) in queryOptions"
            :key="index"
            class="option-item"
            style="margin-top: 6px"
          >
            <div v-if="op.name === 'TableList'">
              <div class="options">
                <span><i class="el-icon-rank"></i>{{ op.title }}</span>
                <span style="float: right"
                  >全选<el-checkbox
                    v-model="op.isShow"
                    style="margin-left: 20px"
                    @change="queryOptionsIsShow($event, op)"
                /></span>
              </div>
              <draggable
                :list="op.items"
                :group="'queryOptions' + index"
                handler=".el-icon-rank"
                :options="dragOption"
              >
                <div
                  class="options"
                  v-for="(item, index) in op.items"
                  :key="item.code + index"
                  style="padding-left: 20px"
                >
                  <span><i class="el-icon-rank"></i>{{ item.title }}</span>
                  <span style="display: flex; align-items: center"
                    ><i
                      class="el-icon-setting"
                      style="padding-top: 3px; margin-right: 5px"
                    ></i
                    ><el-checkbox v-model="item.isShow"
                  /></span>
                </div>
              </draggable>
            </div>
            <div class="options" v-else>
              <span><i class="el-icon-rank"></i>{{ op.title }}</span>
              <span>
                <i
                  class="el-icon-setting"
                  style="padding-top: 3px; margin-right: 5px"
                  @click="handleSetDefaultVal($event, op)"
                ></i>
                <el-checkbox v-model="op.isShow" />
              </span>
            </div>
          </div>
        </draggable>
        <div style="margin: 20px 0; padding-right: 20px">
          <span style="font-weight: 700">列表字段</span>
          <span style="float: right"
            >全选<el-checkbox
              v-model="listCheckedAll"
              style="margin-left: 20px"
              @change="handlerListCheckedAll"
          /></span>
        </div>
        <draggable
          :list="listOptions"
          group="listOptions"
          handler=".el-icon-rank"
          :options="dragOption"
        >
          <div
            v-for="(op, index) in listOptions"
            :key="index"
            class="option-item"
            style="margin-top: 6px"
          >
            <div v-if="op.name === 'TableList'">
              <div class="options">
                <span><i class="el-icon-rank"></i>{{ op.title }}</span>
                <span style="float: right"
                  >全选<el-checkbox
                    v-model="op.isShow"
                    style="margin-left: 20px"
                    @change="queryOptionsIsShow($event, op)"
                /></span>
              </div>
              <draggable
                :list="op.items"
                :group="'TableList' + index"
                handler=".el-icon-rank"
                :options="dragOption"
              >
                <div
                  class="options"
                  v-for="(item, index) in op.items"
                  :key="item.code + index"
                  style="padding-left: 20px"
                >
                  <span><i class="el-icon-rank"></i>{{ item.title }}</span>
                  <el-checkbox v-model="item.isShow" />
                </div>
              </draggable>
            </div>
            <div class="options" v-else>
              <span><i class="el-icon-rank"></i>{{ op.title }}</span>
              <el-checkbox v-model="op.isShow" />
            </div>
          </div>
        </draggable>
      </div>
      <div class="setSort">
        <el-button :loading="loading" @click="setSort">保存</el-button>
      </div>
    </el-aside>
  </el-container>
</template>
<script>
import draggable from "vuedraggable";
import { getItemViewList, updateItemView } from "@/api/process";
import components from "@/views/home/CompExport";
export default {
  name: "TableDesign",
  components: { draggable, ...components },
  data() {
    return {
      data: [],
      queryCheckedAll: false,
      listCheckedAll: false,
      formData: {},
      dragOption: {
        animation: 300,
        sort: true,
      },
      listOptions: [],
      queryOptions: [],
      loading: false,
      isShowOption: false,
      location: {
        left: 0,
        top: 0,
      },
      defaultOption: "",
      defaultOptions: [],
      activeComp: {},
    };
  },
  computed: {
    isShowQueryOptions() {
      let res = JSON.parse(JSON.stringify(this.queryOptions));
      let arr = [];
      res.forEach((item) => {
        if (item.name === "TableList") {
          item.items.forEach((comp) => {
            comp.search = true;
            arr.push(comp);
          });
        } else {
          item.search = true;
          arr.push(item);
        }
      });
      arr = arr.filter(
        (item) => item.name !== "ImageUpload" && item.name !== "FileUpload"
      );
      console.log(arr);

      return arr;
    },
  },
  watch: {
    listOptions: {
      deep: true,
      handler() {
        this.listOptions.forEach((item) => {
          if (item.items.length > 0) {
            if (item.items.every((item) => item.isShow === true))
              item.isShow = true;
            else item.isShow = false;
          }
        });
        if (this.listOptions.every((item) => item.isShow === true))
          this.listCheckedAll = true;
        else this.listCheckedAll = false;
      },
    },
    queryOptions: {
      deep: true,
      handler() {
        this.queryOptions.forEach((item) => {
          if (item.items.length > 0) {
            if (item.items.every((item) => item.isShow === true))
              item.isShow = true;
            else item.isShow = false;
          }
        });
        if (this.queryOptions.every((item) => item.isShow === true))
          this.queryCheckedAll = true;
        else this.queryCheckedAll = false;
      },
    },
  },
  created() {
    this.getList();
  },
  methods: {
    changeDefaultVal(val) {
      this.activeComp.defaultValue = val;
      this.isShowOption = false;
    },
    handleSetDefaultVal(e, item) {
      console.log(e, item);
      if (["DateTime", "Created", "Updated"].includes(item.name)) {
        this.activeComp = item;
        this.location = {
          left: e.x - 210,
          top: e.y,
        };
        this.defaultOptions = [
          "--",
          "当天",
          "昨天",
          "明天",
          "本周",
          "上周",
          "下周",
          "本月",
          "上月",
          "下月",
          "本季度",
          "本年度",
          "上年度",
          "下年度",
        ];
        this.isShowOption = true;
      }
    },
    queryOptionsIsShow(val, item) {
      item.items.forEach((k) => {
        k.isShow = val;
      });
    },
    flexColumnWidth(str) {
      str = str + "";
      // 以下分配的单位长度可根据实际需求进行调整
      let flexWidth = 0;
      for (const char of str) {
        if ((char >= "A" && char <= "Z") || (char >= "a" && char <= "z")) {
          // 如果是英文字符，为字符分配8个单位宽度
          flexWidth += 14;
        } else if (char >= "\u4e00" && char <= "\u9fa5") {
          // 如果是中文字符，为字符分配15个单位宽度
          flexWidth += 20;
        } else {
          // 其他种类字符，为字符分配8个单位宽度
          flexWidth += 8;
        }
      }
      if (flexWidth < 70) {
        // 设置最小宽度
        flexWidth = 70;
      }
      return flexWidth + 28 + "px";
    },
    handlerQueryCheckedAll(val) {
      if (val) {
        this.queryOptions.forEach((item) => {
          if (item.items) item.items.map((k) => (k.isShow = val));
          item.isShow = val;
        });
      } else {
        this.queryOptions.forEach((item) => {
          if (item.items) item.items.map((k) => (k.isShow = val));
          item.isShow = val;
        });
      }
    },
    handlerListCheckedAll(val) {
      if (val) {
        this.listOptions.forEach((item) => {
          if (item.items) item.items.map((k) => (k.isShow = val));
          item.isShow = val;
        });
      } else {
        this.listOptions.forEach((item) => {
          if (item.items) item.items.map((k) => (k.isShow = val));
          item.isShow = val;
        });
      }
    },
    async getList() {
      let res = await getItemViewList(this.$route.query.code);
      // console.log(JSON.parse(JSON.stringify(res.data.search)));
      this.listOptions = res.data.field;
      this.queryOptions = res.data.search;
    },
    setSort() {
      this.loading = true;
      updateItemView({
        formId: this.$route.query.code,
        fieldList: this.listOptions,
        searchList: this.queryOptions,
      }).then(() => {
        this.$notify({
          title: "修改列表字段成功",
          type: "success",
        });
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.layout-param {
  border: 1px solid #e8e8e8;
  color: #304265;
  font-size: 14px;
  box-sizing: border-box;
  padding-bottom: 60px;
  .fontSize {
    text-align: center;
    line-height: 40px;
    font-size: 16px;
    font-weight: 700;
  }
  .setSort {
    .el-button {
      width: 300px;
      height: 50px;
    }
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 1;
    // cursor: pointer;
  }
}

.options {
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
  .el-icon-setting {
    display: none;
  }
  &:hover {
    .el-icon-setting {
      display: inline-block;
      cursor: pointer;
    }
  }
}

.el-icon-rank {
  margin-right: 5px;
  cursor: move;
}
.option-item-label {
  height: 30px;
  line-height: 30px;
  button {
    float: right;
  }
}

/deep/ .el-input-group {
  width: 88%;
}

.el-form-item {
  white-space: nowrap;
}
/deep/ .el-form-item__content {
  line-height: 36px;
  width: calc(100% - 100px);
}

@media only screen and (max-width: 1100px) {
  .el-form-item {
    width: calc(100% - 30px);
  }
}

@media only screen and (min-width: 1100px) {
  .el-form-item {
    width: calc(50% - 30px);
  }
}

@media only screen and (min-width: 1600px) {
  .el-form-item {
    width: calc(33% - 30px);
  }
}

/*/deep/ .el-form-item {
  margin-bottom: 10px;

  .el-form-item__label {
    padding: 0;
  }
  .options{
    .el-icon-rank{
      cursor: move;
    }
  }
}*/
</style>